@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700;800;900&family=Ramabhadra&family=Roboto+Slab&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif !important;
    color: #625038;
    background-color: #F6C88D;
}

a {
    text-decoration: none;
}

button {
    background-color: #625038;
}

button:hover {
    background-color: #705b40 !important;
}

.css-ij8qjo {
    width: 100%;
    max-width: 360px;
    background-color: #625038;
    color: #fff;
    height: calc(100vh - 120px);
    overflow-y: scroll;
}

/* the slides */
.slick-slide {
    padding: 0 16px;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@page {
    /* size: A4; */
    margin: 30px;
}

@media print {
    html, body {
        width: 210mm;
        /* height: 297mm; */
    }

    .p-hide {
        display: none;
    }
}